import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TravelType } from 'src/app/core/enums/travel-type';
import { Checklist } from '../../core/models/checklist/checklist';

@Component({
  selector: 'app-checklist-selector-modal',
  templateUrl: './checklist-selector-modal.component.html',
  styleUrls: ['./checklist-selector-modal.component.scss']
})
export class ChecklistSelectorModalComponent implements OnInit {
  @Input() checklists: Checklist[];

  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  public onDismiss() {
    this.modalController.dismiss();
  }

  public buttonPressed(checklist: Checklist) {
    this.modalController.dismiss({
      checklist
    });
  }
}
