import { IsAppInfo } from '../interfaces/is-app-info';
import packageJson from '../../../../package.json';

export const getAppInfo = ((): IsAppInfo => {
  const appInfo: IsAppInfo = {
    name: packageJson.name,
    version: packageJson.version,
    author: packageJson.author,
    homepage: packageJson.homepage,
    privacyPage: packageJson.privacyPage
  };
  return appInfo;
});

export const wait = (ms: number): Promise<void> => new Promise<void>((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
});

export const isObject = (obj: any): boolean => obj !== null && typeof obj === 'object';
