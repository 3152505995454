import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'src/constants/endpoints';
import { environment } from 'src/environments/environment';
import { Theme } from '../../models/theme';
import { StorageService } from '../storage/storage.service';
import { STORAGE_KEYS } from '../../../../constants/storage-keys';
import { AuthService } from '../auth/auth.service';
import { Platform } from '@ionic/angular';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private api: string;
  private endpoint: string;
  private theme: Theme;
  private storageKey = STORAGE_KEYS.theme;
  private themeActivated: boolean;
  private themeUpdated = new EventEmitter<Theme>();
  private isNative: boolean;

  private base64Identifier = 'data:image/png;base64, ';


  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private authService: AuthService,
    private platform: Platform,
    private fileService: FileService) {
    this.api = environment.api;
    this.endpoint = ENDPOINTS.theme;
    this.isNative = this.platform.is('capacitor');
  }

  public async getTheme(): Promise<Theme | undefined> {
    // Return cached theme if available
    if (this.theme) {
      return this.theme;
    }

    // Try to fetch theme from storage
    const themeFromStorage: Theme = await this.getThemeFromStorage();
    if (themeFromStorage) {
      // Return theme from storage
      this.theme = themeFromStorage;
    } else {
      // Get server access token
      const isLoggedIn = !!this.authService.getAccessToken();
      if (isLoggedIn) {
        // Get theme from server
        const themeFromServer: Theme = await this.getThemeFromServer().toPromise();
        this.theme = themeFromServer;

        if (this.isNative) {
          // Download the logo
          const logoBlob = await this.fileService.downloadFileFromUrl(this.theme.logoUrl).toPromise();
          // Convert to base64
          const logoBase64 = await this.fileService.convertBlobToBase64(logoBlob);

          // Get the code only
          const base64CodeOnly = logoBase64.split(',')[1];

          // Write the logo to local storage
          const writeResult = await this.fileService.writeFileLocal('logos/logo.png', base64CodeOnly);
          // Update the logoUrl with the base64 code
          this.theme.logoUrl = writeResult.uri;
        }

        // Save the fetched theme in storage for future use
        await this.saveThemeInStorage(themeFromServer);

        // Let listeners know that the theme has been updated
        this.themeUpdated.emit(themeFromServer);
      }
    }

    // If theme is on native and the logoUrl is not a base64 string, convert it to base64
    if (this.theme && this.isNative && !this.theme.logoUrl.includes(this.base64Identifier)) {
      this.fileService.getBase64FromLocalUri(this.theme.logoUrl).then(base64 => {
        this.theme.logoUrl = this.base64Identifier + base64;
      });
    }

    return this.theme;
  }

  public getThemeUpdateWatcher(): EventEmitter<Theme> {
    return this.themeUpdated;
  }

  public async removeTheme(): Promise<void> {
    this.theme = undefined;
    this.themeActivated = false;
    return await this.storageService.removeFromStorage(this.storageKey);
  }

  public activateTheme(theme: Theme): void {
    // Set the theme colors
    document.documentElement.style.setProperty('--ion-color-primary', theme.primaryColor);
    document.documentElement.style.setProperty('--ion-color-secondary', theme.secondaryColor);
    document.documentElement.style.setProperty('--ion-color-tertiary', theme.tertiaryColor);

    // Set the logo
    //TODO: Implement logo setting

    // Set the theme as activated
    this.themeActivated = true;

  }

  public themeIsActivated(): boolean {
    return this.themeActivated;
  }

  private getThemeFromServer(): Observable<Theme> {
    return this.http.get<Theme>(`${this.api}/${this.endpoint}`);
  }

  private async saveThemeInStorage(theme: Theme): Promise<void> {
    return await this.storageService.saveToStorage(this.storageKey, theme);
  }

  private async getThemeFromStorage(): Promise<Theme> {
    return await this.storageService.getFromStorage(this.storageKey) as Theme;
  }
}
