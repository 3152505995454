import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'src/constants/endpoints';
import { environment } from 'src/environments/environment';
import { Directory, Filesystem, ReadFileResult, WriteFileResult } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { MimeTypeList } from '../../enums/mime-type.enum';
import { DateService } from '../date/date.service';
import { RequestService } from '../request/request.service';
import { Request } from '../../interfaces/request';
import { wait } from '../../utils';
import { FileType } from '../../enums/file-type.enum';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  // Api settings
  private api = environment.api;
  private endpoint = ENDPOINTS.file;

  constructor(
    private http: HttpClient,
    private fileOpener: FileOpener,
    private dateService: DateService,
    private requestService: RequestService
  ) {
  }

  public getFilePaths(projectId: string, phaseId: string, activityId: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.api}/${this.endpoint}/paths/${projectId}/${phaseId}/${activityId}`);
  }

  public getFileAsBlob(path: string): Observable<Blob> {
    return this.http.post(`${this.api}/${this.endpoint}`, { path }, { responseType: 'blob' });
  }

  public getFileAsBase64(path: string): Promise<string> {
    return new Promise<string>((resolve) => {
      this.http.post(`${this.api}/${this.endpoint}`, { path }, { responseType: 'blob' }).subscribe(async (blob) => {
        // Get filename
        const filename = path.split('/').pop();
        // Create file object
        const file = new File([blob], filename);
        // Convert to base64
        const base64 = await this.convertFileToBase64(file);

        resolve(base64);
      });
    });
  }

  /**
   * Resolves the uri if file is found or an empty string
   * @param filename Full path including name and extension
   */
  public getLocalFileUri(filename: string): Promise<string> {
    return new Promise<string>(async (resolve) => {
      try {
        // Check if file already exists
        const fileAlreadyOnDevice = await Filesystem.stat({
          path: filename,
          directory: Directory.Data
        });
        // Resolve uri of existing file
        resolve(fileAlreadyOnDevice.uri);
      } catch (error) {
        // Resolve empty on (file not exists) error
        resolve('');
      }
    });
  }

  /**
   * Uploads the given base64 files to the server as jpg image.
   * @param projectId The corresponding project id
   * @param phaseId The corresponding phase id
   * @param activityId The corresponding activity id
   * @param base64Array The array with the files
   * @param fileNamePrefix Optional prefix for the filename
   */
  public uploadBase64Files(
    projectId: string,
    phaseId: string,
    activityId: string,
    base64Array: string[],
    fileExtension: string = 'jpg',
    fileType: FileType = FileType.photo,
    fileNamePrefix?: string): Promise<string[]> {
    const filenames = [];
    return new Promise<string[]>(async (resolve) => {
      // Create form data
      const formData = new FormData();
      // Convert base64 strings to blobs
      const blobs = this.convertBase64ToBlob(base64Array);
      // Loop over the blob array
      for (let index = 0; index < blobs.length; index++) {
        // Get the current blob
        const blob = blobs[index];
        // Get current date and time as string
        const thisMoment = new Date(Date.now());
        let dateTimeString = this.dateService.getDateAndTimeAsString(thisMoment);
        // Replace all : with -
        dateTimeString = dateTimeString.replace(new RegExp(':', 'g'), '-');
        // Replace all spaces with _
        dateTimeString = dateTimeString.replace(new RegExp(' ', 'g'), '_');
        // Create a filename with date + time with milliseconds
        filenames.push(`${fileNamePrefix ? fileNamePrefix + '-' : ''}${dateTimeString}-${thisMoment.getMilliseconds()}.${fileExtension}`);
        // Add the blob to files in the form array
        formData.append('files', blob, filenames[filenames.length - 1]);
        // Wait one millisecond so the next blob gets a unique name as well
        await wait(1);
      }

      // Add the file type to the form data
      formData.append('type', fileType);

      // Upload the form data
      const request = {
        method: 'POST',
        url: `${this.api}/${this.endpoint}/${projectId}/${phaseId}/${activityId}`,
        data: formData
      } as Request;
      this.requestService.addRequest(request);

      resolve(filenames);
    });
  }

  /**
   * Writes file to native device and resolves result
   * @param filePath Full path including name and extension
   * @param data The file as string
   */
  public writeFileLocal(filePath: string, data: string): Promise<WriteFileResult> {
    return new Promise<any>(async (resolve, error) => {
      // Try to write file
      try {
        // Try to create directory
        try {
          // convert filename to array
          const filenameAsArray = filePath.split('/');
          // Remove filename
          filenameAsArray.pop();
          // Get path only
          const path = filenameAsArray.join('/');

          // Create the directories
          await Filesystem.mkdir({
            path,
            directory: Directory.Data,
            recursive: true
          });
        } catch (err) {
          console.error('Unable to create dictionary', err);
        }

        // Write file
        const fileWriteResult = await Filesystem.writeFile({
          path: filePath,
          data,
          directory: Directory.Data
        });

        console.log('Wrote file', fileWriteResult);
        resolve(fileWriteResult);
      } catch (err) {
        console.error('Unable to write file', err);
        error(err);
      }
    });
  }

  public getBase64FromLocalUri(uri: string): Promise<string> {
    return new Promise<string>(async (resolve) => {
      // Get the file
      const file = await Filesystem.readFile({
        path: uri
      });
      // Convert to base64
      const base64 = file.data as string;
      // Resolve base64
      resolve(base64);
    });
  }

  public deleteFileLocal(filePath: string): Promise<void> {
    return new Promise<void>(async (resolve, error) => {
      console.log('deleteFileLocal');
      console.log(filePath);

      // Try to delete file
      try {
        // Delete
        await Filesystem.deleteFile({
          path: filePath,
          directory: Directory.Data
        });

        console.log('Deleted file', filePath);
        resolve();
      } catch (err) {
        console.error('Unable to delete file', err);
        error(err);
      }
    });
  }

  /**
   * Opens file from given path
   * @param fullUri Full device path to file
   */
  public async openFileOnNative(fullUri: string): Promise<void> {
    return new Promise<void>((resolve) => {
      // Get filename
      const filename = fullUri.split('/').pop();
      // Get file mimetype
      const mimeType = this.getMimeType(filename);
      // Open file
      this.fileOpener
        .open(fullUri, mimeType)
        .then(() => {
          console.log('File is opened');
          resolve();
        })
        .catch((e) => console.log('Error opening file', e));
    });
  }

  /**
   * Downloads a file from the given url
   * @param url The url to download the file from
   */
  public downloadFileFromUrl(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });

  }

  /**
   * Converts a file to base64
   * @param file The file to convert
   */
  public convertFileToBase64(file: File): Promise<any> {
    return new Promise<any>((resolve, error) => {
      // Get reader
      const reader = new FileReader();
      // Read file
      reader.readAsDataURL(file);
      // Resolve content
      reader.onload = () => {
        resolve(reader.result);
      };
      // Resolve error
      reader.onerror = (err) => {
        error(err);
      };
    });
  }

  /**
   * Converts a base64 string to a blob
   * @param blob The blob to convert
   */
  public convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string); // Base64 string
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob); // Converts the blob to Base64
    });
  }

  /**
   * Returns the mimetype corresponding to the extension of the filename.
   */
  private getMimeType(filenameWithExtension: string): string {
    const extension = filenameWithExtension.split('.').pop();
    return MimeTypeList[extension];
  }

  /**
   * Converts an array of base64 to and array with blobs
   * @param base64Array The array to convert
   * @param contentType Optional content type
   * @param sliceSize Optional sliceSize
   */
  private convertBase64ToBlob(base64Array: string[], contentType = '', sliceSize = 512): Blob[] {
    // Init blob array
    const blobs = [];
    // Loop over the base64 array
    for (const b64Data of base64Array) {
      // Get bytes
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      // Create a blob from the bytes arrays
      const blob = new Blob(byteArrays, { type: contentType });
      // Add blob to the blobs array
      blobs.push(blob);
    }
    return blobs;
  }
}
