import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {
  }

  /**
   * Saves value in  UserDefaults for iOS or SharedPreferences when Android or local storage when web.
   * @param storageKey The key to use for identification
   * @param value The value to set
   */
  public async saveToStorage(storageKey: string, value: any): Promise<void> {
    return Preferences.set({
      key: storageKey,
      value: JSON.stringify(value)
    });
  }

  /**
   * Returns value from devices storage and save it in service property.
   * Returns null when not present.
   */
  public async getFromStorage(storageKey: string): Promise<any> {
    return await JSON.parse((await Preferences.get({ key: storageKey })).value);
  }

  /**
   * Deletes value from storage.
   */
  public async removeFromStorage(storageKey: string): Promise<void> {
    return await Preferences.remove({ key: storageKey });
  }

  /**
   * Clear stored keys and values.
   */
  public async clearStorage(): Promise<void> {
    return await Preferences.clear();
  }
}
