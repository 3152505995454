// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  debug: true,
  api: 'https://dev-api.brainssoftware-apps.nl',
  // Travel monitoring was using @mauron85/cordova-plugin-background-geolocation
  // This plugin is outdated and not working anymore
  travelMonitoringDisabled: true
};

// http://10.0.2.2:3001 --> Android emulator dev testing.
// http://localhost:3000 --> Local dev testing.
// https://dev-api.brainssoftware-apps.nl --> Dev api on server.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
