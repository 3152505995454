export enum MimeTypeList {
  aac = 'audio/aac',
  abw = 'application/x-abiword',
  arc = 'application/x-freearc',
  avi = 'video/x-msvideo',
  azw = 'application/vndamazonebook',
  bin = 'application/octet-stream',
  bmp = 'image/bmp',
  bz = 'application/x-bzip',
  bz2 = 'application/x-bzip2',
  cda = 'application/x-cdf',
  csh = 'application/x-csh',
  csv = 'text/csv',
  doc = 'application/msword',
  docx = 'application/vndopenxmlformats-officedocumentwordprocessingmldocument',
  eot = 'application/vndms-fontobject',
  epub = 'application/epub+zip',
  gz = 'application/gzip',
  gif = 'image/gif',
  ico = 'image/vndmicrosofticon',
  ics = 'text/calendar',
  jar = 'application/java-archive',
  jpg = 'image/jpeg',
  jpeg = 'image/jpeg',
  json = 'application/json',
  mjs = 'text/javascript',
  mp3 = 'audio/mpeg',
  mp4 = 'video/mp4',
  mpeg = 'video/mpeg',
  mpkg = 'application/vndappleinstaller+xml',
  odp = 'application/vndoasisopendocumentpresentation',
  ods = 'application/vndoasisopendocumentspreadsheet',
  odt = 'application/vndoasisopendocumenttext',
  oga = 'audio/ogg',
  ogv = 'video/ogg',
  ogx = 'application/ogg',
  opus = 'audio/opus',
  otf = 'font/otf',
  png = 'image/png',
  pdf = 'application/pdf',
  php = 'application/x-httpd-php',
  ppt = 'application/vndms-powerpoint',
  pptx = 'application/vndopenxmlformats-officedocumentpresentationmlpresentation',
  rar = 'application/vndrar',
  rtf = 'application/rtf',
  sh = 'application/x-sh',
  svg = 'image/svg+xml',
  swf = 'application/x-shockwave-flash',
  tar = 'application/x-tar',
  tif = 'image/tiff',
  tiff = 'image/tiff',
  ts = 'video/mp2t',
  ttf = 'font/ttf',
  txt = 'text/plain',
  vsd = 'application/vndvisio',
  wav = 'audio/wav',
  weba = 'audio/webm',
  webm = 'video/webm',
  webp = 'image/webp',
  xls = 'application/vndms-excel',
  xlsx = 'application/vndopenxmlformats-officedocumentspreadsheetmlsheet',
  xml = 'application/xml',
  xul = 'application/vndmozillaxul+xml',
  zip = 'application/zip'
}
