import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TrimPipe } from 'src/app/pipes/trim/trim.pipe';
import { FilenameFromPathPipe } from './filenameFromPath/filename-from-path.pipe';
import { SafeUrlPipe } from './safe-html/safe-url.pipe';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [TrimPipe, FilenameFromPathPipe, SafeUrlPipe],
  exports: [TrimPipe, FilenameFromPathPipe, SafeUrlPipe]
})
export class PipesModule {
}
