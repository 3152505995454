export const STORAGE_KEYS = {
  accessToken: 'accessToken',
  pin: 'pin',
  pinIsSetKeyName: 'pinSet',
  pinChecked: 'pinIsOK',
  passwordChangedKeyName: 'passwordChanged',
  isLoggedOutKeyName: 'loggedOut',
  user: 'user',
  language: 'language',
  clientUser: 'clientUser',
  theme: 'white_label_theme'
};
