export const ENDPOINTS = {
  auth: 'auth',
  assignments: 'assignments',
  attachments: 'attachments',
  action: 'action',
  file: 'file',
  projects: 'projects',
  activities: 'activities',
  checklist: 'checklist',
  orders: 'orders',
  materials: 'materials',
  theme: 'theme',
  product: 'product',
  productCategories: 'categories'
};
