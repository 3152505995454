export enum Option {
  assignmentConfirm = 'ASSIGNMENT_CONFIRM',
  manualEnding = 'MANUAL_ENDING',
  editingActivitiesAllowed = 'EDITING_ACTIVITIES_ALLOWED',
  orders = 'ORDERS',
  wharf = 'WHARF',
  orderArtdesc = 'ORDER_ARTDESC',
  material = 'MATERIAL',
  signatureAgree = 'SIGNATURE_AGREE',
  signatureChecklist = 'SIGNATURE_CHECKLIST',
  workSequence = 'WORK_SEQUENCE',
  workSequenceAlways = 'WORK_SEQUENCE_ALWAYS',
  theme = 'THEME',
  orderProducts = 'ORDER_PRODUCTS'
}
